import React, { FC } from "react";
import { Result } from "antd";
import Store from "../assets/context/store";

const ClosingPage: FC = () => {
  const { dealerName } = Store.useContainer();

  return (
    <>
      <Result
        status="success"
        title="Tusind tak for dine oplysninger"
        extra={
          <p>
            Vi behandler nu sagen og afgiver svar direkte til din leverandør/forhandler {dealerName} inden for 24 timer.
            <br /> Har du spørgsmål til denne proces er du mere end velkommen til at kontakte os på tlf.{" "}
            <a href="tel:70261515">70261515</a> (Tast 2), eller skrive ind til os på{" "}
            <a href="mailto:leasing@danskmotorfinans.dk">leasing@danskmotorfinans.dk</a>
          </p>
        }
      />
      {/* <div className="text-center">
        <Button type="primary" ghost onClick={window.close}>
          Afslut og luk
        </Button>
      </div> */}
    </>
  );
};

export default ClosingPage;

import { Col, Divider, Row, Space } from "antd";
import React, { FC, ReactNode } from "react";

interface DetailsProps {
  children: ReactNode;
  borderLess?: boolean;
  className?: string;
}

interface TitleProps {
  children: ReactNode;
}

interface ItemProps {
  label?: ReactNode;
  value?: ReactNode;
  boldValue?: boolean;
  boldLabel?: boolean;
  bold?: boolean;
}

interface ListSubComponents {
  Title: FC<TitleProps>;
  Item: FC<ItemProps>;
}

const Title: FC<TitleProps> = ({ children }) => <div className="details-list-title mt-2">{children}</div>;

const Item: FC<ItemProps> = ({ label, value, boldValue, boldLabel, bold }) => (
  <Row justify="space-between" className="flex-nowrap" gutter={14}>
    <Col className="details-list-label">{boldLabel || bold ? <strong>{label}</strong> : label}</Col>
    {value && <Col className={`details-list-value`}>{boldValue || bold ? <strong>{value}</strong> : value}</Col>}
  </Row>
);

const Details: FC<DetailsProps> & ListSubComponents = ({ children, borderLess, className }) => (
  <div className={`details-list-wp mb-1 ${className || ""}`}>
    <Space className="w-100" split={borderLess ? undefined : <Divider className="m-0" />} direction="vertical">
      {children}
    </Space>
  </div>
);

Details.Item = Item;
Details.Title = Title;

export default Details;

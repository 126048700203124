import React, { FC, useState } from "react";
import { message } from "antd";
import http from "../services/httpService";
import Store from "../assets/context/store";
import getApiEndpoint from "../services/apiEndpoint";
import AccessToSkat from "./accessToSkat";
import ConfirmAndSend from "./confirmAndSend";
import PropertyInfo from "./propertyInfo";
import Steps from "./steps";
import SwornStatement from "./swornStatement";
import UploadDocuments from "./uploadDocuments";
import log from "../services/logService";

const { Step } = Steps;

const StepsPage: FC = () => {
  const { activeStep, nextStep, apiEndpoint, prevStep, prevPage, nextPage, form, setDealerName } = Store.useContainer();
  const [loading, setLoading] = useState<boolean>(false);
  return (
    <Steps
      current={activeStep}
      onNext={() => form.validateFields().then(nextStep)}
      loading={loading}
      onPrev={prevStep}
      onBack={prevPage}
      onFinish={async () => {
        try {
          const values = form.getFieldsValue(true);
          const infoConfirmed = values.infoConfirmed;
          if (!infoConfirmed) {
            form.setFields([{ name: "infoConfirmed", errors: ["Bekræft dine data!"] }]);
            return;
          }
          setLoading(true);
          let formData = new FormData();
          for (const key in values) {
            if (Object.prototype.hasOwnProperty.call(values, key)) {
              let value = values[key];
              if (
                (key === "yellowCardFile" ||
                  key === "identificationFile" ||
                  key === "payCheck1" ||
                  key === "payCheck2" ||
                  key === "payCheck3") &&
                Array.isArray(value)
              ) {
                value = value[0].originFileObj;
              } else if (typeof value === "object") {
                value = JSON.stringify(value);
              }
              formData.append(key, value);
            }
          }
          const { data } = await http.post(apiEndpoint || getApiEndpoint(), formData, {
            headers: { "Content-Type": "multipart/form-data" },
          });
          setDealerName(data?.dealerName || "");
          nextPage();
        } catch (e) {
          message.error(e?.response?.data?.message || "Der opstået en fejl. Prøv igen senere!");
          log(e.response);
          setLoading(false);
        }
      }}
    >
      <Step title="Formueoplysninger">
        <PropertyInfo />
      </Step>
      <Step title="Tro og love erklæring">
        <SwornStatement />
      </Step>
      <Step title="Upload af dokumenter">
        <UploadDocuments />
      </Step>
      <Step title="Adgang til skattemappe">
        <AccessToSkat />
      </Step>
      <Step title="Bekræft og send">
        <ConfirmAndSend />
      </Step>
    </Steps>
  );
};

export default StepsPage;

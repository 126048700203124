import React, { FC, ReactNode, useRef, useState } from "react";
import { InputNumber } from "antd";
import { InputNumberProps } from "antd/lib/input-number";

interface InputPriceProps extends InputNumberProps {
  decimalSize?: number;
  className?: string;
  suffix?: ReactNode;
}
const locale = "da-DK";

const InputPrice: FC<InputPriceProps> = ({ suffix, decimalSize = 4, className, min, ...rest }) => {
  const [prev, setPrev] = useState("");
  const [focused, setFocused] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const extraRef = useRef<HTMLSpanElement>(null);

  const currencyFormatter = (value?: number | string) => {
    if (!value) return "";
    if (value === "-") return "-";
    const res = new Intl.NumberFormat(locale, {
      style: "decimal",
      maximumSignificantDigits: 20,
    }).format(Number(value));
    return prev.endsWith(".") ? res + "," : res;
  };

  const currencyParser = (val?: string) => {
    if (!val || (typeof val === "string" && !val.length)) return "";
    let minus = "";

    if (val.startsWith("-") || val === "0-") {
      minus = "-";
      if (val.length === 1) {
        setPrev(minus);
        return "-";
      }
    }

    try {
      let results = val;
      val = val.replace(/[^0-9,]/g, "");
      val = val.replace(",", ".");
      val = val.replace(/,/g, "");

      if (prev.endsWith(".") && !val.includes(",")) {
        const num = val.slice(0, val.length - 1);
        const dec = val.slice(val.length - 1);
        const prevNum = prev.replace(".", "");
        results = num === prevNum ? `${num}.${dec}` : val;
      } else {
        const arr = val.split(".");
        results = arr[1] && arr[1].length ? `${arr[0]}.${arr[1].slice(0, decimalSize)}` : val;
      }
      setPrev(minus + results);
      return minus + results;
    } catch (error) {
      console.error(error);
      return 0;
    }
  };

  const inputElement = () => {
    const paddingRight = extraRef.current?.offsetWidth ? extraRef.current?.offsetWidth + 8 : "1rem";
    return (
      <InputNumber
        {...rest}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        min={min && focused ? 0 : min}
        className={`${className} no-steps-btns`}
        style={{
          width: 400,
          paddingRight,
        }}
        formatter={currencyFormatter}
        parser={currencyParser}
        ref={inputRef}
      />
    );
  };

  return !suffix ? (
    inputElement()
  ) : (
    <div className="input-number-wp">
      {inputElement()}
      <span ref={extraRef} onClick={() => inputRef.current?.focus()} className="input-number-extra">
        {suffix}
      </span>
    </div>
  );
};

export default InputPrice;

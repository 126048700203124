import React, { FC } from "react";
import { Button, Checkbox, Divider, Form } from "antd";
import InputCPR from "./inputCPR";
import Store from "../assets/context/store";
import { getApiBaseUrl } from "../services/apiEndpoint";

const InitPage: FC = () => {
  const { nextPage, user } = Store.useContainer();
  return (
    <>
      {user?.name ? (
        <h2>Hej {user.name}, velkommen til Dansk Motor Finans A/S’ kreditportal.</h2>
      ) : (
        <h2>Velkommen til Dansk Motor Finans A/S’ kreditportal.</h2>
      )}
      <p>
        Som godkendt leasingvirksomhed af Motorstyrelsen og Finanstilsynet, er vi underlagt visse krav til
        kreditvurdering og hvidvask forebyggelse. Dette flow tager dig igennem de informationer og oplysninger vi har
        brug for at kende for at kunne etablere et leasingforhold med dig.
      </p>
      <p>Inden du starter processen bedes du have følgende klar:</p>
      <ul>
        <li>
          <strong>Sygesikringskort (Det gule)</strong>
        </li>
        <li>
          <strong>Gyldig kørekort</strong>
        </li>
        <li>
          <strong>NemID-kort til</strong>
        </li>
        <li>
          <strong>Seneste 3 måneders lønsedler (Hvis muligt)</strong>
        </li>
      </ul>
      <p className="mb-5">
        Alle oplysninger og dokumenter behandles i alt fortrolighed og ved at bekræfte nedenstående giver du hermed
        samtykke til at vi må indhente og behandle dine informationer, på sådan måde som er angivet i vores
        samtykkeerklæring.
      </p>
      <Form.Item
        wrapperCol={{ md: 10, xs: 24 }}
        name="cpr"
        label="Indtast dit CPR nr."
        rules={[{ len: 10, message: "'CPR nr.' er ugyldigt" }]}
      >
        <InputCPR size="large" />
      </Form.Item>
      <Divider />
      <Form.Item name="termsAccepted" valuePropName="checked">
        <Checkbox>
          Bekræft venligst at du har læst og accepteret vores samtykkeerklæring, som kan{" "}
          <a href={`${getApiBaseUrl()}/static/Samtykkeerklæring_KK_DMF.pdf`} target="_blank" rel="noreferrer">
            læses her.
          </a>
        </Checkbox>
      </Form.Item>
      <Form.Item
        noStyle
        shouldUpdate={(curr, next) => curr?.termsAccepted !== next?.termsAccepted || curr?.cpr !== next?.cpr}
      >
        {({ getFieldValue }) => {
          const cpr = getFieldValue("cpr");
          const cprIsValid = cpr?.length === 10;
          return (
            <div className="fancy-btn-wp">
              <Button
                className="fancy-btn"
                type="primary"
                onClick={nextPage}
                disabled={!getFieldValue("termsAccepted") || !cprIsValid}
              >
                START HER
              </Button>
            </div>
          );
        }}
      </Form.Item>
    </>
  );
};

export default InitPage;

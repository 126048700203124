import { Input } from "antd";
import { InputProps } from "antd/lib/input";
import React, { FC } from "react";
import format from "../util/formatNumbers";

interface InputCPRProps extends InputProps {
  value?: string | number;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const InputCPR: FC<InputCPRProps> = ({ value, onChange, ...rest }) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value?.replace("-", "");
    event.target.value = value;
    onChange && onChange(event);
  };

  return <Input value={format.cpr(value || "")} onChange={handleChange} maxLength={11} {...rest} />;
};

export default InputCPR;

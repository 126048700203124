import React, { FC } from "react";
import { Form, Radio } from "antd";
import InputPrice from "./inputPrice";
import InputNum from "./inputNum";
import { housingTypes } from "../types/types";

const PropertyInfo: FC = () => {
  return (
    <>
      <p className="mb-3">
        I forbindelse med kreditvurdering beder vi dig udfylde nedenstående oplysninger, såfremt du har adgang til
        oplysningerne. Dette vil være med til at sikre en hurtig ekspedition.
      </p>
      <Form.Item name={["propertyInfo", "housingType"]} label="Boligtype">
        <Radio.Group buttonStyle="solid">
          {housingTypes.map((item) => (
            <Radio.Button key={item.id} value={item.id}>
              {item.title}
            </Radio.Button>
          ))}
        </Radio.Group>
      </Form.Item>
      <Form.Item name={["propertyInfo", "livingSpace"]} label="Boligareal">
        <InputNum suffix="kvm" />
      </Form.Item>
      <Form.Item name={["propertyInfo", "adultsCount"]} label="Antal voksne i husstanden">
        <InputNum min={0} max={99} />
      </Form.Item>
      <Form.Item name={["propertyInfo", "childrenCount"]} label="Antal børn i husstanden">
        <InputNum min={0} max={99} />
      </Form.Item>
      <Form.Item name={["propertyInfo", "rentMonthly"]} label="Husleje pr. md.">
        <InputPrice suffix="kr." />
      </Form.Item>
      <Form.Item name={["propertyInfo", "housingExpenses"]} label="El, vand og varme pr. md.">
        <InputPrice suffix="kr." />
      </Form.Item>
      <Form.Item name={["propertyInfo", "availableAmountMonthly"]} label="Rådighedsbeløb pr. md.">
        <InputPrice suffix="kr." />
      </Form.Item>
      <Form.Item name={["propertyInfo", "otherLoansMonthly"]} label="Andre lån pr. md.">
        <InputPrice suffix="kr." />
      </Form.Item>
      <Form.Item name={["propertyInfo", "estimatedMileageYearly"]} label="Anslået kørte kilometer pr. år">
        <InputNum suffix="km" />
      </Form.Item>
    </>
  );
};

export default PropertyInfo;

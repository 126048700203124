import { Form, FormInstance } from "antd";
import { useState } from "react";
import { createContainer } from "unstated-next";
import { CreditInfoType } from "../../types/types";

type PageType = "init" | "steps" | "closing";

interface StoreProps {
  activeStep: number;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  dealerName: string;
  setDealerName: React.Dispatch<React.SetStateAction<string>>;
  currentPage: PageType;
  setCurrentPage: React.Dispatch<React.SetStateAction<PageType>>;
  form: FormInstance<CreditInfoType>;
  user?: UserType;
  apiEndpoint?: string;
  nextStep: () => void;
  prevStep: () => void;
  prevPage: () => void;
  nextPage: () => void;
}

/* 
  Steps: 
  0 : Property information
  1 : sworn statement
  2 : Upload documents
  3 : Access to Skat
  4 : Confirm and send
*/

export interface UserType {
  name: string | null;
  completed: boolean;
}

export interface StoreInitType {
  user: UserType;
  apiEndpoint: string;
}

const useStore = (initialState?: StoreInitType): StoreProps => {
  const [form] = Form.useForm();
  const [dealerName, setDealerName] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<PageType>("init");
  const [activeStep, setActiveStep] = useState<number>(0);
  const nextStep = () => {
    setActiveStep((s) => (s < 4 ? s + 1 : s));
    window.scrollTo(0, 0);
  };
  const prevStep = () => {
    setActiveStep((s) => (s !== 0 ? s - 1 : s));
    window.scrollTo(0, 0);
  };
  const prevPage = () => {
    setCurrentPage((p) => (p === "closing" ? "steps" : "init"));
    window.scrollTo(0, 0);
  };
  const nextPage = () => {
    setCurrentPage((p) => (p === "init" ? "steps" : "closing"));
    window.scrollTo(0, 0);
  };

  return {
    ...initialState,
    currentPage,
    dealerName,
    setDealerName,
    setCurrentPage,
    activeStep,
    setActiveStep,
    nextStep,
    prevStep,
    prevPage,
    nextPage,
    form,
  };
};

const Store = createContainer(useStore);

export default Store;

import React, { FC } from "react";
import { Checkbox, Form } from "antd";
import { getApiBaseUrl } from "../services/apiEndpoint";

const AccessToSkat: FC = () => {
  return (
    <>
      <p>
        Til endelig kreditvurdering får vi brug for adgang til din skattemappe.
        <br />
        Dette kan gøres på følgende måde:
      </p>
      <ol className="guid-steps">
        <li>
          Log på{" "}
          <strong>
            <a href="https://www.tastselv.skat.dk/borger/loginsso" target="_blank" rel="noreferrer">
              TastSelv
            </a>
          </strong>{" "}
          med dit Nemid-nøglekort
        </li>
        <li>
          Vælg <strong>Profil</strong> i højre hjørne
        </li>
        <li>
          Vælg <strong>Giv adgang til rådgivere eller andre</strong>
        </li>
        <li>
          Skriv cpr‐nummer eller cvr‐/se‐nummer på den person, der skal have adgang (Angiv venligst Dansk Motor Finans
          A/S, <strong>CVR: 39311151</strong>)
        </li>
        <li>
          Kryds af i feltet <strong>"Personlige skatteoplysninger"</strong>
        </li>
        <li>Angiv dato for udløb (Vælg 14 dage fra dags dato)</li>
        <li>
          Tryk <strong>Godkend</strong>
        </li>
      </ol>
      <div className="mb-2">
        <video width="100%" height="350" controls>
          <source src={`${getApiBaseUrl()}/static/skattemappe_guide.mp4`} type="video/mp4" />
        </video>
      </div>
      <Form.Item name="confirmAccessToSkat" valuePropName="checked">
        <Checkbox>Jeg bekræfter hermed at have givet adgang til min skattemappe.</Checkbox>
      </Form.Item>
    </>
  );
};

export default AccessToSkat;

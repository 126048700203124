import "./assets/style/app.less";
import { Layout, Result } from "antd";
import AppFooter from "./components/appFooter";
import AppHeader from "./components/appHeader";
import AppContent from "./components/appContent";
import Store, { UserType } from "./assets/context/store";
import { useEffect, useState } from "react";
import Loading from "./components/loading";
import getApiEndpoint from "./services/apiEndpoint";
import http from "./services/httpService";
import log from "./services/logService";
import Honeybadger from "honeybadger-js";

const { Content } = Layout;

function App() {
  const [loading, setLoading] = useState<boolean>(true);
  const [verified, setVerified] = useState<boolean>(false);
  const [response, setResponse] = useState<any>();
  const key = window.location.pathname.split("/")[1];
  const apiEndpoint = `${getApiEndpoint()}/creditPortalEntry/${key}`;

  useEffect(() => {
    async function verifiyKey() {
      try {
        if (typeof key === "string" && key.length !== 0) {
          const { data } = await http.get<UserType>(`${getApiEndpoint()}/creditPortalEntry/${key}`);
          setResponse(data);
          setVerified(!data.completed);
        } else {
          log(`Key not valid`);
          setResponse({ message: "Ikke et gyldigt link!" });
        }
      } catch ({ response }) {
        setResponse(response?.data);
        log(response);
      } finally {
        setLoading(false);
      }
    }
    Honeybadger.setContext({ key });
    verifiyKey();
  }, [key]);

  return (
    <Layout>
      <AppHeader />
      <Content>
        {loading && <Loading loading={loading} message="Vent venligst!" />}
        {!loading && !verified && (
          <Result
            status="error"
            title={response?.message || "Denne side er ikke længere tilgængelig."}
            subTitle="Kontakt Dansk Motor Finans for yderligere information"
          />
        )}
        {!loading && verified && (
          <Store.Provider initialState={{ user: response, apiEndpoint }}>
            <AppContent />
          </Store.Provider>
        )}
      </Content>
      <AppFooter />
    </Layout>
  );
}

export default App;

import React, { FC } from "react";
import { Form } from "antd";
import InitPage from "./initPage";
import Store from "../assets/context/store";
import ClosingPage from "./closingPage";
import StepsPage from "./stepsPage";
import { initValues } from "../types/types";

const AppContent: FC = () => {
  const { currentPage, form } = Store.useContainer();

  return (
    <Form
      form={form}
      wrapperCol={{ span: 16 }}
      labelAlign="left"
      labelCol={{ span: 8 }}
      colon={false}
      initialValues={initValues}
    >
      {currentPage === "init" && <InitPage />}
      {currentPage === "closing" && <ClosingPage />}
      {currentPage === "steps" && <StepsPage />}
    </Form>
  );
};

export default AppContent;

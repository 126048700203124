export interface CreditInfoType {
  cpr: string | null;
  termsAccepted: boolean | null;
  propertyInfo: {
    housingType: number | null;
    livingSpace: number | null;
    adultsCount: number | null;
    childrenCount: number | null;
    rentMonthly: number | null;
    housingExpenses: number | null;
    availableAmountMonthly: number | null;
    otherLoansMonthly: number | null;
    estimatedMileageYearly: number | null;
  };
  swornStatement: {
    rki: boolean | null;
    criminalRecord: boolean | null;
    insaneDriving: boolean | null;
    gangConnection: boolean | null;
    drivingLicence: boolean | null;
    pep: boolean | null;
  };
  confirmAccessToSkat: boolean | null;
  uploadLater: boolean | null;
  infoConfirmed: boolean | null;
}

export const initValues: CreditInfoType = {
  cpr: null,
  termsAccepted: false,
  propertyInfo: {
    housingType: null,
    livingSpace: null,
    adultsCount: null,
    childrenCount: null,
    rentMonthly: null,
    housingExpenses: null,
    availableAmountMonthly: null,
    otherLoansMonthly: null,
    estimatedMileageYearly: null,
  },
  swornStatement: {
    rki: null,
    criminalRecord: null,
    insaneDriving: null,
    gangConnection: null,
    drivingLicence: null,
    pep: null,
  },
  confirmAccessToSkat: false,
  uploadLater: false,
  infoConfirmed: false,
};

export const housingTypes = [
  { id: 0, title: "Ejerbolig" },
  { id: 1, title: "Andelsbolig" },
  { id: 2, title: "Lejebolig" },
];

export const getHousingTypes = (id?: number | null) => {
  if (typeof id === "number" || typeof id === "string") return housingTypes.filter((h) => h.id === id)[0]?.title;

  return "";
};

export const getYesOrNo = (value?: boolean | null) => {
  if (typeof value === "boolean") return value ? "Ja" : "Nej";

  return "";
};

import React, { FC } from "react";
import appConfig from "../appConfig";
import { Button, Col, Dropdown, Layout, Menu, Row } from "antd";
const { Footer } = Layout;

const AppFooter: FC = () => {
  const contactMenu = (
    <Menu>
      <Menu.Item key="phone" onClick={() => window.open(`tel:${appConfig("companyPhone")}`, "_self")}>
        {appConfig("companyPhone")}
      </Menu.Item>
      <Menu.Item key="email" onClick={() => window.open(`mailto:${appConfig("companyEmail")}`, "_self")}>
        {appConfig("companyEmail")}
      </Menu.Item>
    </Menu>
  );
  return (
    <Footer>
      <Row gutter={[16, 8]} align="middle">
        <Col xs={24} md={12}>
          {appConfig("companyName")} © {new Date().getFullYear()}
        </Col>
        <Col xs={24} md={12} className="contact-dropdown-wp">
          <Dropdown overlay={contactMenu} placement="topCenter" arrow>
            <Button className="muted" type="link">
              Kontakt os
            </Button>
          </Dropdown>
        </Col>
      </Row>
    </Footer>
  );
};

export default AppFooter;

import React, { FC } from "react";
import { Button, Divider, Form, Checkbox, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { dummyRequest } from "../util/dummyRequest";

const UploadDocuments: FC = () => {
  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };
  return (
    <Form.Item noStyle shouldUpdate={(curr, next) => curr.uploadLater !== next.uploadLater}>
      {({ getFieldValue, validateFields }) => {
        const uploadLater = getFieldValue("uploadLater");
        const onUploadLaterChange = async () => {
          try {
            await validateFields();
          } catch (e) {}
        };
        return (
          <>
            <p className="mb-3">
              Til identifikation og kreditvurdering bedes du uploade følgende dokumenter/legitimation. Dette kan gøres
              ved at uploade her, eller tage et billede af dokumenterne med din telefons kamera. Sørg gerne for at
              billederne er tydelige og i farver.
            </p>
            <Form.Item
              name="identificationFile"
              valuePropName="fileList"
              getValueFromEvent={normFile}
              rules={[{ required: !uploadLater }]}
            >
              <Upload
                disabled={uploadLater}
                customRequest={dummyRequest}
                name="identificationFile"
                listType="picture"
                maxCount={1}
              >
                <Button disabled={uploadLater} icon={<UploadOutlined />}>
                  Kørekort/pas
                </Button>
              </Upload>
            </Form.Item>
            <Form.Item
              name="yellowCardFile"
              valuePropName="fileList"
              getValueFromEvent={normFile}
              rules={[{ required: !uploadLater }]}
            >
              <Upload
                disabled={uploadLater}
                customRequest={dummyRequest}
                name="yellowCardFile"
                listType="picture"
                maxCount={1}
              >
                <Button disabled={uploadLater} icon={<UploadOutlined />}>
                  Sygesikringskort
                </Button>
              </Upload>
            </Form.Item>
            <Form.Item name="payCheck1" valuePropName="fileList" getValueFromEvent={normFile}>
              <Upload disabled={uploadLater} customRequest={dummyRequest} name="payCheck1" listType="text" maxCount={1}>
                <Button disabled={uploadLater} icon={<UploadOutlined />}>
                  Lønseddel 1
                </Button>
              </Upload>
            </Form.Item>
            <Form.Item name="payCheck2" valuePropName="fileList" getValueFromEvent={normFile}>
              <Upload disabled={uploadLater} customRequest={dummyRequest} name="payCheck2" listType="text" maxCount={1}>
                <Button disabled={uploadLater} icon={<UploadOutlined />}>
                  Lønseddel 2
                </Button>
              </Upload>
            </Form.Item>
            <Form.Item name="payCheck3" valuePropName="fileList" getValueFromEvent={normFile}>
              <Upload disabled={uploadLater} customRequest={dummyRequest} name="payCheck3" listType="text" maxCount={1}>
                <Button disabled={uploadLater} icon={<UploadOutlined />}>
                  Lønseddel 3
                </Button>
              </Upload>
            </Form.Item>

            <Divider orientation="left">Eller</Divider>
            <Form.Item name="uploadLater" valuePropName="checked" wrapperCol={{ span: 24 }}>
              <Checkbox onChange={onUploadLaterChange}>
                Jeg har ikke tilgang til det lige nu, men send mig en mail med påmindelse på dette, så uploader jeg
                senere.
              </Checkbox>
            </Form.Item>
          </>
        );
      }}
    </Form.Item>
  );
};

export default UploadDocuments;

import React, { FC } from "react";
import { Form } from "antd";
import YesNoRadio from "./yesNoRadio";

const SwornStatement: FC = () => {
  const commonRules = [{ required: true }];

  return (
    <>
      <p className="mb-3">
        I forbindelse med hvidvask og afsikring af risici, beder vi dig udfylde nedenstående svar muligheder.
      </p>
      <Form.Item
        name={["swornStatement", "pep"]}
        label="Er du en politisk eksponeret person (PEP)?"
        className="form-item-space-between"
        rules={commonRules}
      >
        <YesNoRadio />
      </Form.Item>
      <Form.Item
        name={["swornStatement", "rki"]}
        label="Er du i RKI?"
        rules={commonRules}
        className="form-item-space-between"
      >
        <YesNoRadio />
      </Form.Item>
      <Form.Item
        name={["swornStatement", "insaneDriving"]}
        label="Har du kørt vanvidskørsel inden for de seneste 10 år?"
        className="form-item-space-between"
        rules={commonRules}
      >
        <YesNoRadio />
      </Form.Item>
      <Form.Item
        name={["swornStatement", "gangConnection"]}
        label="Er du i relation med en bande eller rocker-lignende gruppe?"
        className="form-item-space-between"
        rules={commonRules}
      >
        <YesNoRadio />
      </Form.Item>
      <Form.Item
        name={["swornStatement", "drivingLicence"]}
        label="Har du gyldig førerret til personbil? (Kørekort)"
        className="form-item-space-between"
        rules={commonRules}
      >
        <YesNoRadio />
      </Form.Item>
      <Form.Item
        name={["swornStatement", "criminalRecord"]}
        label="Har du ren straffeattest?"
        className="form-item-space-between"
        rules={commonRules}
      >
        <YesNoRadio />
      </Form.Item>
    </>
  );
};

export default SwornStatement;

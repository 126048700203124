import React, { FC } from "react";
import { Checkbox, Divider, Form } from "antd";
import Store from "../assets/context/store";
import { CreditInfoType, getHousingTypes, getYesOrNo } from "../types/types";
import Details from "./detailsList";
import format from "../util/formatNumbers";

const ConfirmAndSend: FC = () => {
  const { form } = Store.useContainer();
  const creditInfo = form.getFieldsValue(true) as CreditInfoType;
  const { cpr, propertyInfo, swornStatement, confirmAccessToSkat } = creditInfo;
  return (
    <>
      <Details>
        <Details.Item boldValue label="CPR nr." value={format.cpr(cpr || "")} />
      </Details>

      <Details>
        <Details.Title>Formueoplysninger</Details.Title>
        <Details.Item boldValue label="Boligtype" value={getHousingTypes(propertyInfo.housingType)} />
        <Details.Item
          boldValue
          label="Boligareal"
          value={propertyInfo.livingSpace ? propertyInfo.livingSpace + " kvm" : ""}
        />
        <Details.Item boldValue label="Antal voksne i husstanden" value={propertyInfo.adultsCount} />
        <Details.Item boldValue label="Antal børn i husstanden" value={propertyInfo.childrenCount} />
        <Details.Item boldValue label="Husleje pr. md." value={format.price(propertyInfo.rentMonthly)} />
        <Details.Item boldValue label="El, vand og varme pr. md." value={format.price(propertyInfo.housingExpenses)} />
        <Details.Item
          boldValue
          label="Rådighedsbeløb pr. md."
          value={format.price(propertyInfo.availableAmountMonthly)}
        />
        <Details.Item boldValue label="Andre lån pr. md." value={format.price(propertyInfo.otherLoansMonthly)} />
        <Details.Item
          boldValue
          label="Anslået kørte kilometer pr. år"
          value={format.milage(propertyInfo.estimatedMileageYearly)}
        />
      </Details>

      <Details>
        <Details.Title>Tro og love erklæring</Details.Title>
        <Details.Item boldValue label="Er du i RKI?" value={getYesOrNo(swornStatement.rki)} />
        <Details.Item boldValue label="Har du ren straffeattest?" value={getYesOrNo(swornStatement.criminalRecord)} />
        <Details.Item
          boldValue
          label="Har du kørt vanvidskørsel inden for de seneste 10 år?"
          value={getYesOrNo(swornStatement.insaneDriving)}
        />
        <Details.Item
          boldValue
          label="Er du i relation med en bande eller rocker-lignende gruppe?"
          value={getYesOrNo(swornStatement.gangConnection)}
        />
        <Details.Item
          boldValue
          label="Har du gyldig førerret til personbil? (Kørekort)"
          value={getYesOrNo(swornStatement.drivingLicence)}
        />
        <Details.Item
          boldValue
          label="Er du en politisk eksponeret person (PEP)?"
          value={getYesOrNo(swornStatement.pep)}
        />
      </Details>

      <Details className="pt-2">
        <Details.Item
          boldValue
          label="Jeg bekræfter hermed at have givet adgang til min skattemappe."
          value={getYesOrNo(confirmAccessToSkat)}
        />
      </Details>

      <Divider />
      <Form.Item name="infoConfirmed" valuePropName="checked" rules={[{ required: true }]}>
        <Checkbox>Bekræft at alle ovenstående oplysninger er korrekte.</Checkbox>
      </Form.Item>
    </>
  );
};

export default ConfirmAndSend;

import React, { FC } from "react";
import { Layout } from "antd";
import Logo from "./logo";
const { Header } = Layout;

const AppHeader: FC = () => {
  return (
    <Header>
      <Logo />
    </Header>
  );
};

export default AppHeader;

import moment from "moment";
import appConfig from "../appConfig";

const local = "da-DK";

interface NumberformatProps {
  style?: "decimal" | "currency" | "percent" | "unit" | undefined;
  unit?: "kilometer" | "kilogram" | undefined;
  unitDisplay?: "long" | "short" | "narrow" | undefined;
}

type NumFunc = (number?: number | null, props?: NumberformatProps) => string;

const number: NumFunc = (number, props) =>
  typeof number === "number" ? new Intl.NumberFormat(local, props).format(number) : "";

const percent: NumFunc = (number, props) =>
  typeof number === "number"
    ? new Intl.NumberFormat(local, {
        style: "percent",
        maximumFractionDigits: 2,
        ...props,
      }).format(number)
    : "";

type PriceFunc = (number: number | null | undefined, maximumFractionDigits?: number, currency?: string) => string;

const price: PriceFunc = (number, maximumFractionDigits = 0, currency = "DKK") => {
  if (typeof number !== "number") return "";
  let config: Intl.NumberFormatOptions = {
    style: "currency",
    currency,
  };
  if (maximumFractionDigits > 0) config = { ...config, maximumFractionDigits };
  else number = Math.round(number);
  return new Intl.NumberFormat(local, config).format(number).replace(",00", "");
};

const date = (value?: string | null) =>
  typeof value === "string" ? moment(value).format(appConfig("dateFormat")) : "";

const milage = (value?: number | null) => (typeof value === "number" ? `${number(value)} Km` : "");

const time = (value?: string | null, prefix?: boolean) =>
  typeof value === "string" ? `${prefix ? "kl. " : ""}${moment(value).format("HH:mm")}` : "";

const dateAndTime = (value?: string | null) =>
  typeof value === "string" ? `${date(value)} - ${time(value, true)}` : "";

const cpr = (value: string | number) => {
  const indexPosition = 6;
  const newValue = value.toString().replace(/[^0-9]/g, "");
  const results =
    newValue.length > indexPosition ? newValue.slice(0, indexPosition) + "-" + newValue.slice(indexPosition) : newValue;
  return results;
};

const format = { price, number, percent, date, time, dateAndTime, milage, cpr };

export default format;

import Honeybadger from "honeybadger-js";

const config = {
  apiKey: process.env.REACT_APP_HONEYBADGER_KEY || "",
  environment: process.env.NODE_ENV,
  onerror: false,
  disabled: false,
};

if (!!process.env.REACT_APP_HONEYBADGER_FORCE_LOG) {
  // Not sure why the config object does not have this, even though it is in the documentation
  //config.reportData = true
}

if (!process.env.REACT_APP_HONEYBADGER_KEY) {
  config.disabled = true;
}

Honeybadger.configure(config);

export const logContext = Honeybadger.context;
export const setLogContext = Honeybadger.setContext;

type LogType = (...args: any[]) => void;

const log: LogType = Honeybadger.notify;

export default log;

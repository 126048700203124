import { Button, Col, Divider, Form, Row, Typography } from "antd";
import React, { FC } from "react";

interface StepsProps {
  current: number;
  loading?: boolean;
  onNext: () => void;
  onPrev: () => void;
  onBack: () => void;
  onFinish: () => void;
}
interface StepProps {
  title: string;
}
interface StepsSubCopmonent {
  Step: FC<StepProps>;
}

const Steps: FC<StepsProps> & StepsSubCopmonent = ({
  children,
  current,
  onNext,
  onPrev,
  loading,
  onBack,
  onFinish,
}) => {
  const stepNodes = React.Children.toArray(children);
  const step = stepNodes[current] as any;
  const stepTitle = step?.props?.title;
  const isFirstStep = current === 0;
  const isLastStep = current + 1 === stepNodes.length;

  return (
    <div className={`steps-node loading-wp ${loading ? "is-loading" : ""}`}>
      <Row align="middle" justify="space-between">
        <Col>
          <Typography.Title level={4} className="mb-0">
            {stepTitle}
          </Typography.Title>
        </Col>
        <Col>
          <Typography.Title level={4} className="mb-0" type="secondary">
            {current + 1}/{stepNodes.length}
          </Typography.Title>
        </Col>
      </Row>
      <Divider />
      {step}
      <Divider />
      <Row align="middle" justify="space-between">
        <Col>
          <Button type="text" onClick={isFirstStep ? onBack : onPrev} disabled={loading}>
            {isFirstStep ? "Tilbage" : "Forrige"}
          </Button>
        </Col>
        <Col>
          <Form.Item noStyle shouldUpdate={(curr, next) => curr.infoConfirmed !== next.infoConfirmed}>
            {({ getFieldValue }) => {
              const disabled = isLastStep && !getFieldValue("infoConfirmed");
              return (
                <Button type="primary" onClick={isLastStep ? onFinish : onNext} loading={loading} disabled={disabled}>
                  {isLastStep ? "Send" : "Videre"}
                </Button>
              );
            }}
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

const Step: FC<StepProps> = ({ children }) => <>{children}</>;

Steps.Step = Step;

export default Steps;

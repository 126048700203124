/* eslint-disable no-template-curly-in-string */

const typeTemplate = "'${name}' er ikke et gyldigt ${type}";

export const validateMessages = {
  default: "Fejl i feltet '${name}'",
  required: "Dette Feltet er påkrævet!",
  enum: "'${name}' skal være en af [${enum}]",
  whitespace: "Feltet kan ikke være tom!",
  date: {
    format: "'${name}' er ugyldig som datoformat",
    parse: "'${name}' kunne ikke parses som dato",
    invalid: "'${name}' er ugyldig dato",
  },
  types: {
    string: typeTemplate,
    method: typeTemplate,
    array: typeTemplate,
    object: typeTemplate,
    number: typeTemplate,
    date: typeTemplate,
    boolean: typeTemplate,
    integer: typeTemplate,
    float: typeTemplate,
    regexp: typeTemplate,
    email: "Den angivet E-mail adresse er ikke gyldig!",
    url: typeTemplate,
    hex: typeTemplate,
  },
  string: {
    len: "'${name}' skal være præcis ${len} tegn",
    min: "'${name}' skal mindst være ${min} tegn",
    max: "'${name}' kan ikke være længere end ${max} tegn",
    range: "'${name}' skal være imellem ${min} and ${max} tegn",
  },
  number: {
    len: "'${name}' skal være lig ${len}",
    min: "'${name}' kan ikke være mindre end ${min}",
    max: "'${name}' kan ikke være større end ${max}",
    range: "'${name}' skal være imellem ${min} og ${max}",
  },
  array: {
    len: "'${name}' skal være præcis ${len} i længden",
    min: "'${name}' kan ikke være mindre end ${min} i længden",
    max: "'${name}' kan ikke være større end ${max} i længden",
    range: "'${name}' skal være imellem ${min} og ${max} i længden",
  },
  pattern: {
    mismatch: "'${name}' matcher ikke mønsteret ${pattern}",
  },
};

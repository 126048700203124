import { Radio, RadioGroupProps } from "antd";
import React, { FC } from "react";

interface YesNoRadioProps extends RadioGroupProps {
  value?: boolean;
  onChange?: (value: any) => void;
}

const YesNoRadio: FC<YesNoRadioProps> = ({ value, onChange, ...rest }) => {
  const getValue = () => {
    if (typeof value === "boolean") return value ? 1 : 0;
    return value;
  };
  return (
    <Radio.Group
      buttonStyle="solid"
      {...rest}
      value={getValue()}
      onChange={({ target }) => {
        onChange && onChange(!!target.value);
      }}
    >
      <Radio.Button value={1}>Ja</Radio.Button>
      <Radio.Button value={0}>Nej</Radio.Button>
    </Radio.Group>
  );
};

export default YesNoRadio;
